import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  useLocation,
} from "react-router-dom";

import { validate as validateUuid } from 'uuid';

import './App.css';
import Message from './Message';

const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay * 1000));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const NotFound = () => (
  <div>Not found</div>
);

export default function App() {

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/authenticate/:method/:uuid">
          <AuthRequest/>
        </Route>
        <Route path="/callback">
          <AuthResponse/>
        </Route>
        <Route path="*">
            <NotFound />
        </Route>
      </Switch>
    </Router>
  );
}

const NavigateToAuth = ({url}) => {
  useEffect(() => {
    window.location.href = url;
  }, [url]);
  return null;
}

function Home() {
return <div>codehooks.io admin {window._env_.REACT_APP_GITHUB_CALLBACK}</div>;
}

function AuthRequest() {
  const { method, uuid } = useParams();

  if (method==='github' && validateUuid(uuid)){
    const url = `https://github.com/login/oauth/authorize?client_id=${window._env_.REACT_APP_GITHUB_CLIENT_ID}&redirect_uri=${window._env_.REACT_APP_GITHUB_CALLBACK}&state=github,${uuid}&scope=user:email, read:user`;
    return <NavigateToAuth url={url}/>;
  }
  if (method==='google' && validateUuid(uuid)){
    const url = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${window._env_.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=${window._env_.REACT_APP_GOOGLE_CALLBACK}&state=google,${uuid}&scope=https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email&response_type=code`;
    return <NavigateToAuth url={url}/>;
  }
  return <div>
      Unknown provider or invalid request
  </div>;
}

function AuthResponse() {
  const query = useQuery();
  const code = query.get('code');
  const state = query.get('state');
  const [provider, authId ] = state.split(',');

  const [error, setError] = useState(query.get('error'));
  const [result, setResult] = useState(null);

  useEffect(() => {
    if (error) return;
    const options = {
      method: 'POST',
      body: JSON.stringify({code, authId, provider}),
      headers: {
        'Content-Type':'application/json',
      }
    }
    const postAuth = async () => {
      try {
        const response = await fetch(`${window._env_.REACT_APP_FRONTI_API}/verify/${authId}`, options);
        if (response.status === 200){
          const data = await response.json();
          await sleep(1);
          setResult(data);
        } else {
          setError('Unable to authenticate for CLI');
        }
      } catch (err){
        setError('Unable to authenticate for CLI');
      }
    }
    postAuth();
  },[authId, code, provider, error]);

  if (error){
    console.error(error);
    return <Message waiting={false} text="Authenticate error" subText="Unable to log in. Please contact support@codehooks.io or use our chat to get help." type="error"/>
  }

  if (!result){
    return <Message text="Authenticating" />
  };

  return <Message waiting={false} text="Thank you! You can now close this browser window" />
  
}
