import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export default function Message ({text, subText, waiting = true, type = "info"}) {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open
      >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        spacing={3}
      >
        <Box sx={{height: '60px'}}>
          {waiting && type === 'info' && <CircularProgress color="inherit" />}
          {!waiting && type === 'error' && <ErrorOutlineIcon style={{ borderRadius: '25px', fontSize: '50px', backgroundColor: 'white' }} color="error" />}
        </Box>
        <img alt={text || "Logging in to codehooks"} src="/logo.svg"/>
        <Typography sx={{fontWeight: 400 }} component="h1" variant="h6">
          {text || 'Logging you in'}
        </Typography>
        {subText && <Typography component="body2" sx={{ p:2 }}>
          {subText}
          </Typography>}
      </Stack>
    </Backdrop>
  );
}
